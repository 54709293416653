<template>
  <div class="languageBox">
    <nav-bar :title="$t('语言切换')" bg-color="#2c2e2f" left-arrow left-color="#fff" title-color="#fff"/>
    <div class="language">
      <p class="txt" @click="alertLang('zh')">
        <span class="left">{{ $t('中文') }}</span>
        <img v-show="check=='zh'" alt="" class="checkImg" src="@/assets/img/check1.png">
        <img v-show="check!='zh'" alt="" class="checkImg" src="@/assets/img/check2.png">
      </p>
      <span class="line"/>
      <p class="txt" @click="alertLang('en')">
        <span class="left">{{ $t('英文') }}</span>
        <img v-show="check=='en'" alt="" class="checkImg" src="@/assets/img/check1.png">
        <img v-show="check!='en'" alt="" class="checkImg" src="@/assets/img/check2.png">
      </p>
    </div>
    <div class="btnBox">
      <div class="btn" @click="save()">{{ $t('保存') }}</div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      check: ''
    }
  },
  computed: {},
  watch: {},
  methods: {
    alertLang(lang) {
      this.check = lang
      // this.local.set("lang", lang)
    },
    save() {
      this.local.set("lang", this.check)
      this.$router.go(0)
      // window.location.reload()
      // this.$router.back()
    },
  },
  created() {
    this.check = this.local.get("lang")
  },
  mounted() {
  },
}
</script>

<style lang="less" scoped>
.languageBox {
  width: 100%;
  min-height: 100vh;
  background-color: #2c2e2f;
  padding: 20px 15px;

  .language {
    border-radius: 10px;
    padding: 15px;
    background-color: #343638;

    .line {
      display: block;
      width: 100%;
      height: 1px;
      margin: 15px 0;
      background-color: #3e4042;
    }

    .txt {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        color: #ffffff;
      }

      .checkImg {
        width: 15px;
        height: 15px;
        border-radius: 50%;
      }
    }
  }

  .btnBox {
    margin-top: 100px;
    padding: 0 15px;

    .btn {
      background-color: #b79d65;
      color: #ffffff;
      padding: 10px 0;
      text-align: center;
      border-radius: 10px;
      font-size: 16px;
    }
  }
}
</style>
